import 'mdb-vue-ui-kit/css/mdb.min.css'
import 'mdb-vue-ecommerce-gallery/css/mdb-vue-ecommerce-gallery.min.css'
import 'mdb-vue-mention/css/mdb-vue-mention.min.css'
import 'mdb-vue-onboarding/css/mdb-vue-onboarding.min.css'
import 'mdb-vue-multi-carousel/css/mdb-vue-multi-carousel.min.css'
import 'mdb-vue-transfer/css/mdb-vue-transfer.min.css'
import 'mdb-vue-cookies/css/mdb-vue-cookies.min.css'
import 'mdb-vue-storage/css/mdb-vue-storage.min.css'
import 'mdb-vue-filters/css/mdb-vue-filters.min.css'
import 'mdb-vue-wysiwyg-editor/css/mdb-vue-wysiwyg-editor.min.css'
import 'mdb-vue-tree-view/css/mdb-vue-tree-view.min.css'
import 'mdb-vue-file-upload/css/mdb-vue-file-upload.min.css'
import 'mdb-vue-vector-maps/css/mdb-vue-vector-maps.min.css'
import 'mdb-vue-drag-and-drop/css/mdb-vue-drag-and-drop.min.css'
import 'mdb-vue-calendar/css/mdb-vue-calendar.min.css'
import 'mdb-vue-table-editor/css/mdb-vue-table-editor.min.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'

import './plugins/axios'

const app = createApp(App)

app.use(router)

app.mount('#app')
