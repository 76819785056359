<template id="sidebar">
    <!-- Sidenav-->
    <MDBSideNav
            v-model="sidenavDark"
            id="sidenavDark"
            color="light"
            dark
            bg="dark"
            style="margin-top: 90px;"
    >
        <MDBSideNavMenu>
            <router-link
                    to="users"
                    @click="sidenavDark = !sidenavDark"
            >
                <MDBSideNavItem>
                    <MDBSideNavLink>
                        <MDBIcon iconStyle="far" icon="user" class="fa-fw me-3" />
                        <span>Usuários</span>
                    </MDBSideNavLink>
                </MDBSideNavItem>
            </router-link>
            <router-link
                    to="/projetos"
                    @click="sidenavDark = !sidenavDark"
            >
                <MDBSideNavItem>
                    <MDBSideNavLink>
                        <MDBIcon iconStyle="far" icon="images" class="fa-fw me-3" />
                        <span>Projetos</span>
                    </MDBSideNavLink>
                </MDBSideNavItem>
            </router-link>
            <router-link
                    to="/#"
                    @click="sidenavDark = !sidenavDark; doLogout()"
            >
                <MDBSideNavItem>
                    <MDBSideNavLink>
                        <MDBIcon iconStyle="fas" icon="power-off" class="fa-fw me-3" />
                        <span>Logout</span>
                    </MDBSideNavLink>
                </MDBSideNavItem>
            </router-link>
        </MDBSideNavMenu>
    </MDBSideNav>
    <!-- Sidenav-->
    <!-- Toggler-->
    <div style="padding: 20px" class="text-lg-start sandwich">
        <MDBBtn
                color="dark"
                aria-controls="#sidenavDark"
                @click="sidenavDark = !sidenavDark"
        >
            <MDBIcon icon="bars" />
        </MDBBtn>
    </div>
    <!-- Toggler-->
</template>

<script>
    import { MDBSideNav, MDBSideNavMenu, MDBSideNavItem, MDBSideNavLink, MDBIcon, MDBBtn } from "mdb-vue-ui-kit";
    import { ref } from 'vue';
    export default {
        components: {
            MDBSideNav,
            MDBSideNavMenu,
            MDBSideNavItem,
            MDBSideNavLink,
            MDBIcon,
            MDBBtn
        },
        setup() {
            const sidenavDark = ref(false);
            return {
                sidenavDark
            }
        },
        methods: {
            doLogout() {
                localStorage.removeItem('user')
                localStorage.removeItem('token')
                localStorage.removeItem('isLoggedIn')
            }
        },
    };
</script>

<style scoped>
    #sidebar {
        margin-top: 90px !important;
    }
    .sandwich {
        position: absolute;
        top: 5px;
    }
    a {
        text-decoration: none;
        color: #FFF;
    }
</style>