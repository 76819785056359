<template>
    <div class="sticky" style="overflow: hidden">
        <the-navbar/>
        <the-sidebar/>
    </div>
    <div class="form-projeto">
        <MDBRow tag="form" class="g-3 needs-validation" novalidate @submit.prevent="checkForm">
            <MDBCol md="6">
                <MDBInput
                        label="Nome"
                        v-model="name"
                        invalidFeedback="Esse campo é obrigatório!"
                        placeholder="Nome do Usuário"
                        validFeedback="Tudo certo!"
                        required
                />
            </MDBCol>
            <MDBCol md="6">
                <MDBInput
                        label="E-mail"
                        v-model="email"
                        invalidFeedback="Esse campo é obrigatório!"
                        placeholder="E-mail do Usuário"
                        validFeedback="Tudo certo!"
                        required
                />
            </MDBCol>
            <MDBCol md="4">
                <MDBInput
                        label="Senha"
                        type="password"
                        v-model="password"
                        invalidFeedback="Esse campo é obrigatório!"
                        placeholder="Senha do Usuário"
                        validFeedback="Tudo certo!"
                        required
                />
            </MDBCol>
            <MDBCol md="4">
                <MDBInput
                        label="Confirmação de senha"
                        type="password"
                        v-model="password_confirmation"
                        invalidFeedback="Esse campo é obrigatório!"
                        placeholder="Confirmação da senha do Usuário"
                        validFeedback="Tudo certo!"
                        required
                />
            </MDBCol>

            <MDBCol md="4">
                <MDBSelect
                        v-model:options="roleOptions"
                        v-model:selected="selectedOption"
                        ref="selectRole"
                        validFeedback="Tudo certo!"
                        required
                />
            </MDBCol>

            <MDBCol col="12">
                <MDBBtn color="primary" type="submit" :disabled="isLoading">
                    <MDBSpinner v-if="isLoading" tag="span" size="sm" />
                    {{isLoading?'Salvando...':'Salvar'}}
                </MDBBtn>
            </MDBCol>
        </MDBRow>
    </div>
</template>

<script>
    import TheNavbar from "../src/components/layout/TheNavbar";
    import TheSidebar from "../src/components/layout/TheSidebar";
    import {MDBBtn, MDBCol, MDBInput, MDBRow, MDBSelect, MDBSpinner} from "mdb-vue-ui-kit";
    import {ref} from "vue";
    import defaultRequest from "../src/axiosConfig";
    import router from "@/router";

    export default {
        name: "EditarUsuario",
        components: {
            TheNavbar,
            TheSidebar,
            MDBCol,
            MDBRow,
            MDBInput,
            MDBBtn,
            MDBSelect,
            MDBSpinner,
        },
        setup() {
            const selectedOption = ref("");
            const name = ref("");
            const id = ref("");
            const password = ref("");
            const password_confirmation = ref("");
            const email = ref("");
            let isLoading = ref(false);
            const roleOptions = ref([
                { text: "Admin", value: 1 },
                { text: "User", value: 2 },
            ]);

            const checkForm = e => {
                isLoading.value = true
                e.target.classList.add("was-validated");
                if (
                    name.value !== ''
                    && password.value !== ''
                    && password_confirmation.value !== ''
                    && email.value !== ''
                    && selectedOption.value !== ''
                ) {
                    let formData = {
                        'name': name.value,
                        'email': email.value,
                        'password': password.value,
                        'password_confirmation': password_confirmation.value,
                        'access_level': selectedOption.value,
                    }
                    defaultRequest
                        .put('/user/'+id.value, formData)
                        .then(() => {
                            router.push('/users')
                        })
                        .catch(error => {
                            if (typeof error.response.data.data !== 'undefined') {
                                const errObj = error.response.data.data
                                let errStr = ''
                                Object.keys(errObj).forEach(key => {
                                    errStr += (key + ': ' + errObj[key] + '\n')
                                });
                                alert(errStr)
                            }
                        });
                }
                isLoading.value = false
            };
            return {
                selectedOption,
                isLoading,
                id,
                name,
                email,
                password,
                password_confirmation,
                checkForm,
                roleOptions,
            };
        },
        created() {
            defaultRequest
                .get('/user/'+this.$route.params.id, {
                    responseType: "json",
                })
                .then((response) => {
                    this.id = response.data.data.id
                    this.name = response.data.data.name
                    this.email = response.data.data.email
                    this.selectedOption = response.data.data.access_level == 'admin'? 1:2
                    this.$refs.selectRole.setValue(this.selectedOption)
                });
        },
    }
</script>

<style scoped>
    .form-projeto {
        width: 50%;
        margin: 100px auto;
    }
    @media (max-width: 768px) {
        .form-projeto {
            width: 100%;
            margin: 70px auto;
            padding: 30px;
        }
    }

</style>