<template>
    <!-- Container for demo purpose -->
    <div>
        <!--Section: Design Block-->
        <section class="py-5 mb-8">
            <MDBContainer class="my-5 py-5">
                <MDBRow center>
                    <MDBCol xl="4" lg="5" md="6" class="text-center">
                        <div>
                            <img
                                    src="@/assets/logo.png"
                                    height="32"
                                    width="129"
                                    alt="logo"
                                    style="margin-bottom: 30px"
                            />
                            <MDBInput
                                    type="email"
                                    label="Email"
                                    id="loginEmail"
                                    v-model="loginEmail"
                                    wrapperClass="mb-2"
                            />
                            <MDBInput
                                    type="password"
                                    label="Password"
                                    id="loginPassword"
                                    v-model="loginPassword"
                                    wrapperClass="mb-4"
                            />
                            <div :class="errorObject">* e-mail ou senha incorretos</div>
                            <div class="d-flex justify-content-between mb-4">
                                <a href="/esqueci-senha">Esqueceu a senha?</a>
                            </div>
                            <MDBBtn @click.prevent="handleLogin" color="dark" block> Entrar </MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
        <!--Section: Design Block-->
    </div>
    <!-- Container for demo purpose -->
</template>
<script>
    import {
        MDBContainer,
        MDBRow,
        MDBCol,
        MDBInput,
        MDBBtn,
    } from "mdb-vue-ui-kit";
    import defaultRequest from "../src/axiosConfig";
    export default {
        components: {
            MDBContainer,
            MDBRow,
            MDBCol,
            MDBInput,
            MDBBtn,
        },
        methods: {
            handleLogin() {
                defaultRequest
                    .post('/login', {
                        email: this.loginEmail,
                        password: this.loginPassword
                    })
                    .then(response => {
                        localStorage.user = response.data.user;
                        localStorage.token = response.data.access_token;
                        localStorage.isLoggedIn = true;
                        this.$router.push({path: '/projetos'});
                    })
                    .catch(error => {
                        this.loginError = true;
                        console.log(error.response)
                });
            },
        },
        data: () => {
            return {
                loginEmail: '',
                loginPassword: '',
                loginError: false,
            }
        },
        computed: {
            errorObject() {
                return {
                    'no-error': !this.loginError,
                    'has-error': this.loginError
                }
            }
        },
    };
</script>

<style scoped>
    #app {
        font-family: Roboto, Helvetica, Arial, sans-serif;
    }
    .no-error {
        display: none;
    }
    .has-error {
        color: red;
        text-align: left;
        padding-top: 0;
        margin-top: -20px;
        margin-bottom: 25px;
        font-size: 12px;
    }
</style>