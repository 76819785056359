import { createRouter, createWebHistory } from 'vue-router'
import Login from "../../pages/Login";
import Users from "../../pages/Users";
import Projetos from "../../pages/Projetos";
import NovoProjeto from "../../pages/NovoProjeto";
import EditarProjeto from "../../pages/EditarProjeto";
import NovoUsuario from "../../pages/NovoUsuario";
import EditarUsuario from "../../pages/EditarUsuario";
import EsqueciSenha from "../../pages/EsqueciSenha";

const router = createRouter({
  history: createWebHistory(),
  scrollBehavior () {
    return { left: 0, top: 0 }
  },
  routes: [
    { path: '/', component: Login },
    { path: '/login', component: Login },
    { path: '/esqueci-senha', component: EsqueciSenha },
    { path: '/users', component: Users, meta: { requiresAuth: true} },
    { path: '/projetos', component: Projetos, meta: { requiresAuth: true}  },
    { path: '/add-projeto', name:'novo-projeto', component: NovoProjeto, meta: { requiresAuth: true}  },
    { path: '/add-user', name:'novo-user', component: NovoUsuario, meta: { requiresAuth: true}  },
    { path: '/editar/video/:slug', name:'editar-video', component: EditarProjeto, props: true, meta: { requiresAuth: true}  },
    { path: '/editar/user/:id', name:'editar-usuario', component: EditarUsuario, props: true, meta: { requiresAuth: true}  },
  ],
});

router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem("isLoggedIn") == null || localStorage.getItem("isLoggedIn") === false) {
      next({
        path: "/login",
        params: { nextUrl: to.fullPath },
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;

