<template v-if="localStorage && localStorage.user">
    <MDBNavbar light bg="dark" expand="dark" container>
        <MDBNavbarBrand href="#">
            <img
                    src="@/assets/logo-branco.png"
                    height="64"
                    width="258"
                    style="margin-left: 100px;"
            >
        </MDBNavbarBrand>
        <MDBNavbarToggler target="navbarNav"></MDBNavbarToggler>
    </MDBNavbar>
</template>

<script>
    import {
        MDBNavbar,
        MDBNavbarToggler,
        MDBNavbarBrand,
    } from 'mdb-vue-ui-kit';
    export default {
        components: {
            MDBNavbar,
            MDBNavbarToggler,
            MDBNavbarBrand,
        },
    };
</script>

<style scoped>
    img {
        background-color: transparent;
    }
</style>
