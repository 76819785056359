<template>
  <router-view/>
  <div class="end-space"></div>
</template>

<script>
  export default {
    components: {

    },
    data: () => {
      return {
      }
    },
    setup() {
      const isLoggedIn = localStorage && localStorage.getItem("user") !== null
      return {
        isLoggedIn
      }
    },
    watch: {

    }
  }
</script>

<style>
  #app {
    font-family: Roboto, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    text-align: center;
    color: #2c3e50;
  }
  a {
    text-decoration: none;
  }
  body {
    overflow: hidden;
  }
  .navbar-light .navbar-toggler {
    color: transparent !important;
  }
  .end-space {
    margin-top: 150px;
  }

</style>
