<template>
    <div class="sticky" style="overflow: hidden">
        <the-navbar/>
        <the-sidebar/>
    </div>
    <div class="form-projeto">
        <MDBRow tag="form" class="g-3 needs-validation" novalidate @submit.prevent="checkForm">
            <MDBCol md="12">
                <MDBInput
                        label="Título"
                        v-model="titulo"
                        invalidFeedback="Esse campo é obrigatório!"
                        placeholder="Titulo do projeto"
                        validFeedback="Tudo certo!"
                        required
                />
            </MDBCol>
            <MDBCol md="12">
                <MDBTextarea
                        label="Texto"
                        rows="8"
                        v-model="texto"
                        required
                        placeholder="Texto apresentado no site"
                        invalidFeedback="Esse campo é obrigatório!"
                        validFeedback="Tudo certo!"
                />
            </MDBCol>
            <MDBCol md="12">
                <MDBInput
                        label="Source"
                        v-model="source"
                        placeholder="Source do video"
                        invalidFeedback="Esse campo é obrigatório!"
                        validFeedback="Tudo certo!"
                        required
                />
            </MDBCol>
            <MDBCol md="8">
                <MDBInput
                        :formOutline="false"
                        type="file"
                        v-model="filtro"
                        id="filtro"
                />
            </MDBCol>
            <MDBCol md="4">
                <MDBInput
                        label="Nome do filtro"
                        v-model="nome_filtro"
                        invalidFeedback="Esse campo é obrigatório!"
                        required
                />
            </MDBCol>
            <MDBCol md="8">
                <MDBInput
                        :formOutline="false"
                        type="file"
                        v-model="preview"
                        id="preview"
                />
            </MDBCol>
            <MDBCol md="4">
                <MDBInput
                        label="Posição"
                        v-model="posicao"
                        placeholder="Posição do Projeto"
                        validFeedback="Tudo certo!"
                />
            </MDBCol>

            <MDBCol col="12">
                <MDBBtn color="primary" type="submit" :disabled="isLoading">
                    <MDBSpinner v-if="isLoading" tag="span" size="sm" />
                    {{isLoading?'Salvando...':'Salvar'}}
                </MDBBtn>
            </MDBCol>
        </MDBRow>
    </div>
</template>

<script>
    import TheNavbar from "../src/components/layout/TheNavbar";
    import TheSidebar from "../src/components/layout/TheSidebar";
    import {MDBBtn, MDBCol, MDBInput, MDBRow, MDBTextarea, MDBSpinner} from "mdb-vue-ui-kit";
    import {ref} from "vue";
    import defaultRequest from "../src/axiosConfig";
    import router from "@/router";

    export default {
        name: "EditarProjeto",
        components: {
            TheNavbar,
            TheSidebar,
            MDBCol,
            MDBRow,
            MDBInput,
            MDBBtn,
            MDBTextarea,
            MDBSpinner,
        },
        setup() {
            const titulo = ref("");
            const texto = ref("");
            const source = ref("");
            const filtro = ref([]);
            const nome_filtro = ref("");
            const preview = ref([]);
            const videoId = ref("");
            const filterId = ref("");
            const posicao = ref("");
            let isLoading = ref(false);

            const checkForm = e => {
                isLoading.value = true
                e.target.classList.add("was-validated");
                let filtroImg = document.querySelector('#filtro');
                let videoPreview = document.querySelector('#preview');
                console.log(filtroImg.files[0])
                if (titulo.value !== ''
                    && texto.value !== ''
                    && source.value !== ''
                    && filtro.value !== ''
                ) {
                    let formData = new FormData();
                    formData.append('name', nome_filtro.value)
                    if (typeof filtroImg.files[0] != 'undefined') {
                        formData.append('filter', filtroImg.files[0])
                    }
                    formData.append('_method', 'PUT')
                    defaultRequest
                        .post('/filter/'+filterId.value, formData,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data'
                            }
                        })
                        .then(response => {
                            let formDataVideo = new FormData();
                            formDataVideo.append('_method', 'PUT')
                            formDataVideo.append('user_id', 1)
                            formDataVideo.append('filter_id', response.data.id)
                            formDataVideo.append('title', titulo.value)
                            formDataVideo.append('source', source.value)
                            formDataVideo.append('slug', titulo.value)
                            formDataVideo.append('text', texto.value)
                            formDataVideo.append('position', posicao.value)
                            if (typeof videoPreview.files[0] != 'undefined') {
                                formDataVideo.append('thumbnail', videoPreview.files[0])
                            }
                            defaultRequest
                                .post('/video/'+videoId.value, formDataVideo,
                                    {
                                        headers: {
                                            'Content-Type': 'multipart/form-data'
                                        }
                                    })
                                .then(() => {

                                })
                                .catch(error => {
                                    if (typeof error.response.data.data !== 'undefined') {
                                        const errObj = error.response.data.data
                                        let errStr = ''
                                        Object.keys(errObj).forEach(key => {
                                            errStr += (key + ': ' + errObj[key] + '\n')
                                        });
                                        alert(errStr)
                                    }
                                });
                            router.push({path: '/projetos'});
                        })
                        .catch(error => {
                            if (typeof error.response.data.data !== 'undefined') {
                                const errObj = error.response.data.data
                                let errStr = ''
                                Object.keys(errObj).forEach(key => {
                                    errStr += (key + ': ' + errObj[key] + '\n')
                                });
                                alert(errStr)
                            }
                        });
                }
                isLoading.value = false
            };
            return {
                titulo,
                texto,
                source,
                filtro,
                nome_filtro,
                preview,
                checkForm,
                videoId,
                filterId,
                posicao,
                isLoading,
            };
        },
        data: () => {
            return {
                images: [],
                resp: {},
                searchTitle: '',
                url: '',
            }
        },
        created() {
            defaultRequest
                .get('/videoinformation/slug/'+this.$route.params.slug, {
                    responseType: "json",
                })
                .then((response) => {
                    this.titulo = response.data.data.title
                    this.texto = response.data.data.text
                    this.source = response.data.data.source
                    this.nome_filtro = response.data.data.filter.name
                    this.videoId = response.data.data.id
                    this.filterId = response.data.data.filter.id
                    this.posicao = response.data.data.position
                });
        },
    }
</script>

<style scoped>
    .form-projeto {
        width: 50%;
        margin: 100px auto;
    }
    @media (max-width: 768px) {
        .form-projeto {
            width: 100%;
            margin: 70px auto;
            padding: 30px;
        }
    }


</style>