<template>
    <popup-modal ref="popup">
        <h3 style="margin-top: 0">{{ title }}</h3>
        <p>{{ message }}</p>
        <div class="btns">
            <button class="cancel-btn" @click="_cancel">{{ cancelButton }}</button>
            <button class="ok-btn" @click="_confirm">{{ okButton }}</button>
        </div>
    </popup-modal>
</template>

<script>
    import PopupModal from './PopupModal.vue'

    export default {
        name: 'ConfirmDialogue',

        components: { PopupModal },

        data: () => ({
            // Parameters that change depending on the type of dialogue
            title: undefined,
            message: undefined, // Main text content
            okButton: undefined, // Text for confirm button; leave it empty because we don't know what we're using it for
            cancelButton: 'Não', // text for cancel button

            // Private variables
            resolvePromise: undefined,
            rejectPromise: undefined,
        }),

        methods: {
            show(opts = {}) {
                this.title = opts.title
                this.message = opts.message
                this.okButton = opts.okButton
                if (opts.cancelButton) {
                    this.cancelButton = opts.cancelButton
                }
                // Once we set our config, we tell the popup modal to open
                this.$refs.popup.open()
                // Return promise so the caller can get results
                return new Promise((resolve, reject) => {
                    this.resolvePromise = resolve
                    this.rejectPromise = reject
                })
            },

            _confirm() {
                this.$refs.popup.close()
                this.resolvePromise(true)
            },

            _cancel() {
                this.$refs.popup.close()
                this.resolvePromise(false)
                // Or you can throw an error
                // this.rejectPromise(new Error('User cancelled the dialogue'))
            },
        },
    }
</script>

<style scoped>
    .btns {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }

    .ok-btn {
    height: 40px;
    appearance: none;
    background-color: #2ea44f;
    border: 1px solid rgba(27, 31, 35, .15);
    border-radius: 6px;
    box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
    box-sizing: border-box;
    color: #fff;
    cursor: pointer;
    display: inline-block;
    font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
    font-size: 14px;
    font-weight: 600;
    line-height: 20px;
    padding: 6px 16px;
    position: relative;
    text-align: center;
    text-decoration: none;
    user-select: none;
    -webkit-user-select: none;
    touch-action: manipulation;
    vertical-align: middle;
    white-space: nowrap;
    }

    .ok-btn:focus:not(:focus-visible):not(.focus-visible) {
        box-shadow: none;
        outline: none;
    }

    .ok-btn:hover {
        background-color: #2c974b;
    }

    .ok-btn:focus {
        box-shadow: rgba(46, 164, 79, .4) 0 0 0 3px;
        outline: none;
    }

    .ok-btn:disabled {
        background-color: #94d3a2;
        border-color: rgba(27, 31, 35, .1);
        color: rgba(255, 255, 255, .8);
        cursor: default;
    }

    .ok-btn:active {
        background-color: #298e46;
        box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
    }

    .cancel-btn {
        height: 40px;
        appearance: none;
        background-color: #f5163f;
        border: 1px solid rgba(27, 31, 35, .15);
        border-radius: 6px;
        box-shadow: rgba(27, 31, 35, .1) 0 1px 0;
        box-sizing: border-box;
        color: #fff;
        cursor: pointer;
        display: inline-block;
        font-family: -apple-system,system-ui,"Segoe UI",Helvetica,Arial,sans-serif,"Apple Color Emoji","Segoe UI Emoji";
        font-size: 14px;
        font-weight: 600;
        line-height: 20px;
        padding: 6px 16px;
        position: relative;
        text-align: center;
        text-decoration: none;
        user-select: none;
        -webkit-user-select: none;
        touch-action: manipulation;
        vertical-align: middle;
        white-space: nowrap;
    }

    .cancel-btn:focus:not(:focus-visible):not(.focus-visible) {
        box-shadow: none;
        outline: none;
    }

    .cancel-btn:hover {
        background-color: #fc3056;
    }

    .cancel-btn:focus {
        box-shadow: rgba(194, 40, 20, .4) 0 0 0 3px;
        outline: none;
    }

    .cancel-btn:disabled {
        background-color: #fc3056;
        border-color: rgba(27, 31, 35, .1);
        color: rgba(255, 255, 255, .8);
        cursor: default;
    }

    .cancel-btn:active {
        background-color: #f5163f;
        box-shadow: rgba(20, 70, 32, .2) 0 1px 0 inset;
    }
</style>