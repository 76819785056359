<template>
    <div class="sticky" style="overflow: hidden">
        <the-navbar/>
        <the-sidebar/>
    </div>
    <section class="add-user">
        <MDBBtn color="primary" floating tag="a" href="/add-user">
            <MDBIcon icon="plus">
            </MDBIcon>
        </MDBBtn>
    </section>
    <!-- Container for demo purpose -->
    <MDBContainer class="my-5 py-5">
        <!--Section: Design Block-->
        <section class="mb-8">
            <MDBCard>
                <MDBCardHeader class="card-header">Usuários</MDBCardHeader>
                <MDBCardBody class="p-0">
                    <div class="table-responsive">
                        <MDBTable responsive striped class="mb-0">
                            <thead
                                    class="table-light"
                                    style="
                  border-top-left-radius: 1rem;
                  border-top-right-radius: 1rem;
                "
                            >
                            <tr>
                                <th scope="col" style="border-top-left-radius: 1rem">NOME</th>
                                <th scope="col">ID</th>
                                <th scope="col">EMAIL</th>
                                <th scope="col">ACESSO</th>
                                <th scope="col" style="border-top-right-radius: 1rem"></th>
                                <th scope="col" style="border-top-right-radius: 1rem"></th>
                            </tr>
                            </thead>
                            <tbody class="fw-normal">
                            <tr
                                    v-for="(user, i) in users"
                                    :key="i"
                            >
                                <th scope="row">{{user.name.replace(/(^|\s)\S/g, l => l.toUpperCase())}}</th>
                                <td class="text-muted">{{user.id}}</td>
                                <td class="text-muted">{{user.email}}</td>
                                <td class="text-muted">{{user.access_level.replace(/(^|\s)\S/g, l => l.toUpperCase())}}</td>
                                <td class="text-end">
                                    <a :href="'/editar/user/'+user.id" style="font-weight: 500">Editar</a>
                                </td>
                                <td class="text-end">
                                    <a @click="doDelete(user.id)" style="font-weight: 500;cursor: pointer;color: #1266F1">Deletar</a>
                                </td>
                            </tr>
                            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
                            </tbody>
                        </MDBTable>
                    </div>
                </MDBCardBody>
            </MDBCard>
        </section>
        <!--Section: Design Block-->
    </MDBContainer>
    <!-- Container for demo purpose -->
</template>

<script>
    import { MDBContainer, MDBCard, MDBCardBody, MDBTable, MDBBtn, MDBIcon } from "mdb-vue-ui-kit";
    import TheSidebar from "../src/components/layout/TheSidebar";
    import TheNavbar from "../src/components/layout/TheNavbar";
    import defaultRequest from "../src/axiosConfig";
    import ConfirmDialogue from "../src/components/modal/ConfirmDialogue";
    export default {
        components: {
            TheSidebar,
            TheNavbar,
            MDBContainer,
            MDBCard,
            MDBCardBody,
            MDBTable,
            MDBBtn,
            MDBIcon,
            ConfirmDialogue,
        },
        data: () => {
            return {
                users: []
            }
        },
        created() {
            defaultRequest
                .get('/user', {
                    responseType: "json",
                })
                .then((response) => {
                    this.users = response.data.map((element) => element);
                });
        },
        methods: {
            async doDelete(id) {
                const ok = await this.$refs.confirmDialogue.show({
                    title: 'Confirmação de exclusão',
                    message: 'Tem certeza que quer deletar esse usuário?',
                    okButton: 'Sim',
                })
                if (ok) {
                    defaultRequest
                        .delete('/user/'+id, {
                            responseType: "json",
                        })
                        .then(() => {
                            let users = this.users
                            for (let i = users.length - 1; i >= 0; --i) {
                                if (users[i].id == id) {
                                    users.splice(i,1);
                                    this.users = users
                                }
                            }
                            alert('Usuário excluido')
                        });
                }
            }
        },
    };
</script>

<style scoped>
    #app {
        font-family: Roboto, Helvetica, Arial, sans-serif;
    }
    body {
        background-color: hsl(0, 0%, 98%);
    }
    .add-user {
        position: absolute;
        right: 30px;
        top: 120px;
    }
</style>