<template>
    <div class="sticky" style="overflow: hidden">
        <the-navbar/>
        <the-sidebar/>
        <section class="searchbar">
            <MDBInput
                    inputGroup
                    :formOutline="false"
                    wrapperClass="mb-3"
                    class="rounded"
                    v-model="searchTitle"
                    @change="searchHandler"
                    aria-describedby="search-addon"
                    aria-label="Search"
                    placeholder="Search"
            >
            </MDBInput>
        </section>
        <section class="add-project">
            <MDBPopconfirm>
                <MDBBtn color="primary" floating tag="a" href="/add-projeto">
                    <MDBIcon icon="plus">
                    </MDBIcon>
                </MDBBtn>
            </MDBPopconfirm>
        </section>
    </div>
    <div
            class="infinite-scroll py-3 text-center"
            v-mdb-infinite-scroll="loadImages"
            style="max-height: 75vh; overflow-y: scroll"
    >
        <div id="projeto-cards">
            <MDBCard
                    v-for="(image, i) in images"
                    :key="i"
                    class="img-fluid mb-3 projeto"
                    style="display: flex"
            >
                <MDBCardImg top :src="image" alt="..."/>
                <MDBCardBody>
                    <MDBCardTitle>{{resp[i].title}}</MDBCardTitle>
                    <MDBCardText>
                        {{resp[i].text}}
                    </MDBCardText>
                </MDBCardBody>
                <MDBListGroup flush>
                    <MDBListGroupItem>Slug: <a>{{resp[i].slug}}</a></MDBListGroupItem>
                </MDBListGroup>
                <MDBCardBody>
                    <MDBCardLink :href="resp[i].source" target="_blank">Video link</MDBCardLink>
                    <MDBCardLink :href="'/editar/video/'+resp[i].slug">Editar</MDBCardLink>
                    <MDBCardLink @click="doDelete(resp[i].id)" class="pointer">Excluir</MDBCardLink>

                </MDBCardBody>
            </MDBCard>
            <confirm-dialogue ref="confirmDialogue"></confirm-dialogue>
        </div>
        <MDBSpinner v-if="isLoading" class="mx-auto" />
    </div>
</template>

<script>
    import {
        mdbInfiniteScroll,
        MDBSpinner,
        MDBCard,
        MDBCardBody,
        MDBCardTitle,
        MDBCardText,
        MDBCardLink,
        MDBCardImg,
        MDBInput,
        MDBBtn,
        MDBIcon
    } from 'mdb-vue-ui-kit';
    import TheNavbar from "../src/components/layout/TheNavbar";
    import TheSidebar from "../src/components/layout/TheSidebar";
    import defaultRequest from "../src/axiosConfig";
    import ConfirmDialogue from "../src/components/modal/ConfirmDialogue";
    export default {
        directives: {
            mdbInfiniteScroll
        },
        components: {
            MDBSpinner,
            MDBCard,
            MDBCardBody,
            MDBCardTitle,
            MDBCardText,
            MDBCardLink,
            MDBCardImg,
            TheNavbar,
            TheSidebar,
            MDBInput,
            MDBBtn,
            MDBIcon,
            ConfirmDialogue,
        },
        data: () => {
            return {
                images: [],
                resp: {},
                searchTitle: '',
                url: '',
            }
        },
        created() {
            defaultRequest
                .get('/video', {
                    responseType: "json",
                })
                .then((response) => {
                    this.images = response.data.map((element) => `${process.env.VUE_APP_STORAGE_ROOT}/storage/filters/${encodeURIComponent(element.filter.location)}`);
                    this.resp = response.data.map((element) => element);
                });
        },
        methods: {
            async doDelete(id) {
                const ok = await this.$refs.confirmDialogue.show({
                    title: 'Confirmação de exclusão',
                    message: 'Tem certeza que quer deletar esse prjeto?',
                    okButton: 'Sim',
                })
                if (ok) {
                    defaultRequest
                        .delete('/video/'+id, {
                            responseType: "json",
                        })
                        .then(() => {
                            let resp = this.resp
                            let images = this.images
                            for (let i = resp.length - 1; i >= 0; --i) {
                                if (resp[i].id == id) {
                                    resp.splice(i,1);
                                    images.splice(i,1)
                                    this.resp = resp
                                    this.images =images
                                }
                            }
                            alert('Projeto excluido')
                        });
                }
            },
            searchHandler() {
                if (this.searchTitle === '') {
                    this.url = 'video/';
                } else {
                    this.url = '/videoinformation/title/' + this.searchTitle
                }
                defaultRequest
                    .get(this.url, {
                        responseType: "json",
                    })
                    .then((response) => {
                        let data = [];
                        if (this.searchTitle === '') {
                            data = response.data;
                        } else {
                            data = response.data.data;
                        }
                        this.images = data.map((element) => `${process.env.VUE_APP_STORAGE_ROOT}/storage/filters/${encodeURIComponent(element.filter.location)}`);
                        this.resp = data.map((element) => element);
                    });
            }
        },
    };
</script>

<style scoped>
    .projeto {
        width: 50%;
        padding: 30px;
        margin-left: 25%;
    }
    .searchbar {
        width: 20%;
        position: sticky;
        margin-left: 75%;
        margin-top: 30px;
    }
    .add-project {
        position: absolute;
        right: 30px;
        top: 120px;
    }
    .pointer {
        cursor: pointer;
    }
    @media (max-width: 768px) {
        .projeto {
            width: 100%;
            padding: 30px;
            margin-left: 0;
        }
        .searchbar {
            width: 75%;
            padding: 5px;
            margin-left: 5%;
            position: sticky;
            margin-top: 30px;
        }
        .add-project {
            position: absolute;
            right: 30px;
            top: 150px;
        }
        .navbar-light .navbar-toggler {
            color: transparent !important;
        }

    }
</style>