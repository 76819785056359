<template>
    <!-- Container for demo purpose -->
    <div>
        <!--Section: Design Block-->
        <section class="py-5 mb-8">
            <MDBContainer class="my-5 py-5">
                <MDBRow center>
                    <MDBCol xl="4" lg="5" md="6" class="text-center">
                        <div>
                            <img
                                    src="@/assets/logo.png"
                                    height="32"
                                    width="129"
                                    alt="logo"
                                    style="margin-bottom: 30px"
                            />
                            <MDBInput
                                    type="email"
                                    label="Email"
                                    id="loginEmail"
                                    v-model="loginEmail"
                                    wrapperClass="mb-2"
                            />
                            <MDBBtn @click.prevent="handleReset" color="dark" block> Enviar </MDBBtn>
                        </div>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        </section>
        <!--Section: Design Block-->
    </div>
    <!-- Container for demo purpose -->
</template>
<script>
    import {
        MDBContainer,
        MDBRow,
        MDBCol,
        MDBInput,
        MDBBtn,
    } from "mdb-vue-ui-kit";
    import defaultRequest from "../src/axiosConfig";
    export default {
        components: {
            MDBContainer,
            MDBRow,
            MDBCol,
            MDBInput,
            MDBBtn,
        },
        methods: {
            handleReset() {
                defaultRequest
                    .post('/auth-reset', {
                        email: this.loginEmail,
                    })
                    .then(() => {
                        alert('Email de recuperação enviado!')
                        this.$router.push({path: '/login'});
                    })
                    .catch(error => {
                        this.loginError = true;
                        console.log(error.response)
                    });
            }
        },
        data: () => {
            return {
                loginEmail: '',
                loginError: false,
            }
        },
        computed: {
            errorObject() {
                return {
                    'no-error': !this.loginError,
                    'has-error': this.loginError
                }
            }
        },
    };
</script>

<style scoped>
    #app {
        font-family: Roboto, Helvetica, Arial, sans-serif;
    }
    .no-error {
        display: none;
    }
    .has-error {
        color: red;
        text-align: left;
        padding-top: 0;
        margin-top: -20px;
        margin-bottom: 25px;
        font-size: 12px;
    }
</style>